<template>
  <div class="d-flex">
    <v-col cols="12" sm="6" lg="4" class="col-bordered" align="center">
      <span class="key-title">Conformidades obrigatórias: </span>
      <section>
        <g-chart
          type="PieChart"
          :data="[
            ['Categoria', 'Quantidade'],
            ['Realizado', parseInt(getValue('relevancia_1'))],
            ['Não realizado', 100 - parseInt(getValue('relevancia_1'))],
          ]"
          :options="chartOptionsObrigatorio"
          :create-chart="drawPieChart"
        />
        <p>{{ getValue('relevancia_1') + '%' }}</p>
      </section>
    </v-col>
    <v-col cols="12" sm="6" lg="4" class="col-bordered" align="center">
      <span class="key-title">Conformidades relevantes:</span>
      <section>
        <g-chart
          type="PieChart"
          :data="[
            ['Categoria', 'Quantidade'],
            ['Conforme', parseInt(getValue('relevancia_2'))],
            ['Não Conforme', 100 - parseInt(getValue('relevancia_2'))],
          ]"
          :options="chartOptionsRelevante"
          :create-chart="drawPieChart"
        />
        <p>{{ getValue('relevancia_2') + '%' }}</p>
      </section>
    </v-col>
    <v-col cols="12" sm="6" lg="4" class="col-bordered" align="center">
      <span class="key-title">Conformidades aceitáveis:</span>
      <section>
        <g-chart
          type="PieChart"
          :data="[
            ['Categoria', 'Quantidade'],
            ['Realizado', parseInt(getValue('relevancia_3'))],
            ['Não realizado', 100 - parseInt(getValue('relevancia_3'))],
          ]"
          :options="chartOptionsAceitavel"
          :create-chart="drawPieChart"
        />
        <p>{{ getValue('relevancia_3') + '%' }}</p>
      </section>
    </v-col>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ConformGraph',

  data() {
    return {
      rotate: -90,
      chartOptionsObrigatorio: {
        legend: 'none',
        chartArea: { height: '80%', width: '50%' },
        tooltip: { isHtml: true, trigger: 'none' },
        responsive: true,
        maintainAspectRatio: false,
        pieHole: 0.85,
        pieSliceText: 'none',
        slices: {
          0: { color: '#dd2c00' },
          1: { color: '#ff6e40' },
        },
      },
      chartOptionsRelevante: {
        legend: 'none',
        chartArea: { height: '80%', width: '50%' },
        tooltip: { isHtml: true, trigger: 'none' },
        responsive: true,
        maintainAspectRatio: false,
        pieHole: 0.85,
        pieSliceText: 'none',
        slices: {
          0: { color: '#ff6f00' },
          1: { color: '#ffe0b2' },
        },
      },
      chartOptionsAceitavel: {
        legend: 'none',
        chartArea: { height: '80%', width: '50%' },
        tooltip: { isHtml: true, trigger: 'none' },
        responsive: true,
        maintainAspectRatio: false,
        pieHole: 0.85,
        pieSliceText: 'none',
        slices: {
          0: { color: '#1b5e20' },
          1: { color: '#43a047' },
        },
      },
    }
  },

  computed: {
    ...mapGetters('report', ['getValue']),
  },

  methods: {
    drawPieChart(el, google) {
      if (el === undefined) {
        return
      }
      return new google.visualization.PieChart(el)
    },
  },
}
</script>

<style>
section {
  width: auto;
  height: 200px;
  text-align: center;
  max-width: 400px;
}

section p {
  position: relative;
  margin-top: -30%;
  font-size: 20px;
  color: #333;
}
</style>
