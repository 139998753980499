<template>
  <v-col v-if="getValue(component.name)" cols="12" style="padding: 0">
    <div class="col-bordered px-2 py-2">
      <span class="key-title">{{ component.label }} </span>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th v-for="label in linesLabels()" :key="label" class="text-left">
                {{ label }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="reportItem in multiForm()" :key="reportItem.name">
              <td v-for="key in linesKeys()" :key="key">
                {{ reportItem[key] }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'MultiInsertForm',

  props: {
    component: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters('report', ['getValue']),
  },

  methods: {
    multiForm() {
      const value = this.getValue(this.component.name)

      if (!value) {
        return []
      }

      return JSON.parse(value)
    },

    linesKeys() {
      return this.component.fields.map(field => field.name)
    },

    linesLabels() {
      return this.component.fields.map(field => field.label)
    },
  },
}
</script>

<style></style>
