<template>
  <v-col cols="12" class="col-bordered">
    <span class="key-title">{{ component.title }} </span>

    <div v-for="item in component.fields" :key="item.name">
      <span v-if="getValue(item.name)" class="value-title">
        {{ item.label }}
      </span>
    </div>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SomeCheckbox',

  props: {
    component: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters('report', ['getValue']),
  },
}
</script>
