import moment from 'moment'
import XLSX from 'xlsx-js-style'
import reportServices from '../../api/reports'
import { EXCLUDED_COMPONENTS, EXCLUDED_FIELDS } from './SheetFields'

export default class SheetGenerator {
  constructor(structure, reports) {
    if (!structure || !reports) {
      throw new Error('Structure and reports are required')
    }

    this.structure = structure
    this.reports = reports
    this.headers = []
    this.wb = XLSX.utils.book_new()
  }

  generateFields() {
    const fields = []
    const excludedFields = []

    const recursive = items => {
      items.forEach(field => {
        if (field.componentType === 'buttonSelect' && !field?.fields) return

        if (field.componentType === 'multiInsertForm') {
          excludedFields.push(field)
          return
        }

        if (field.fields) recursive(field.fields)
        if (field.fields1) recursive(field.fields1)

        if (!EXCLUDED_COMPONENTS.includes(field.componentType)) {
          fields.push(field)
        } else {
          excludedFields.push(field)
        }
      })
    }

    this.structure.steps.forEach(step => {
      recursive(step.fields)
    })

    return { fields, excludedFields }
  }

  styleHeaders(ws, headers) {
    headers.forEach((item, index) => {
      const cell = XLSX.utils.encode_cell({ c: index, r: 0 })

      if (!ws[cell]) return

      ws[cell].s = {
        font: { bold: true, color: { rgb: 'ffffff' }, sz: 14 },
        fill: { fgColor: { rgb: '01579b' } },
      }
      ws['!cols'] = ws['!cols'] || []
      ws['!cols'].push({ wch: item === 'Produtor' ? 50 : item.length + 10 })
    })
  }

  async create(filename = null) {
    const ids = this.reports.map(report => report.CodRelatorio)
    const reports = await reportServices.getReports({ ids })
    await this.createReportsSheets(reports, filename)
  }

  async createReportsSheets(reports, filename) {
    const sortedReports = this.sortReportsByDate(reports.data)
    let { fields, excludedFields } = this.generateFields()

    fields = this.filterFields(fields)
    fields = this.addCustomFields(fields)

    const headers = fields.map(field => field.label)
    const values = sortedReports.map(report =>
      fields.map(field =>
        this.getFieldValue(field, report, this.structure.steps),
      ),
    )

    const ws = XLSX.utils.aoa_to_sheet([headers, ...values])
    this.styleHeaders(ws, headers)
    XLSX.utils.book_append_sheet(this.wb, ws, 'Relatórios')

    excludedFields.forEach(field => {
      if (field.componentType === 'multiInsertForm') {
        this.createMultiInsertFormPage(field, sortedReports)
      }
    })

    const datetime = moment().format('DD-MM-YYYY')
    XLSX.writeFile(this.wb, `${filename}-${datetime}.xlsx`)
  }

  sortReportsByDate(reports) {
    return reports.sort((a, b) => {
      const dateA = moment(
        a.find(item => item.key === 'dataAtendimento').value,
        'DD/MM/YYYY',
      )
      const dateB = moment(
        b.find(item => item.key === 'dataAtendimento').value,
        'DD/MM/YYYY',
      )
      return dateA.isBefore(dateB) ? 1 : -1
    })
  }

  filterFields(fields) {
    return fields
      .filter(field => !EXCLUDED_COMPONENTS.includes(field.name))
      .filter(field => !EXCLUDED_FIELDS.includes(field.name))
  }

  addCustomFields(fields) {
    fields.splice(1, 0, {
      name: 'produtor',
      label: 'Produtor',
      componentType: 'input',
    })
    fields.splice(3, 0, {
      name: 'agroindustria',
      label: 'Agroindústria',
      componentType: 'input',
    })
    return fields
  }

  getFieldValue(field, report, structure) {
    const parent = this.findImmediateParentByNameInArray(structure, field.name)

    if (
      parent?.componentType === 'buttonSelect' &&
      this.getValue(parent?.name, report) !== '1'
    ) {
      return
    }

    if (
      field.componentType === 'buttonSelect' &&
      this.getValue(field.name, report) == '1'
    ) {
      return 'Sim'
    }

    if (
      field.componentType == 'checkbox' &&
      this.getValue(field.name, report) == '1'
    ) {
      return 'Sim'
    }

    if (field.componentType == 'someCheckbox' && field.fields) {
      const values = field.fields.filter(
        f => this.getValue(f.name, report) == '1',
      )

      return values.map(f => f.label).join(', ')
    }

    if (field.componentType === 'buttonSelect' && field.option) {
      const value = this.getValue(field.option, report)
      return value && value.includes(field.value) ? 'Sim' : 'Não'
    }

    if (field.componentType === 'selection') {
      const findField = field.fields.find(f => this.getValue(f.name, report))
      return findField ? findField.label : ''
    }

    if (field.componentType === 'buttonSelect' && !field.fields) {
      return
    }

    const find = report.find(item => item.key === field.name)
    return find ? find.value : ''
  }

  getValue(key, report) {
    const find = report.find(item => item.key === key)
    return find ? find.value : ''
  }

  findImmediateParentByNameInArray(structureArray, name) {
    for (const structure of structureArray) {
      const result = this.findImmediateParentByName(structure, name)
      if (result) {
        return result
      }
    }
    return null
  }

  findImmediateParentByName(structure, name) {
    if (structure.fields && structure.fields.length > 0) {
      for (const field of structure.fields) {
        if (field.name === name) {
          return structure
        }

        const result = this.findImmediateParentByName(field, name)
        if (result) {
          return result
        }
      }
    }
    return null
  }

  createMultiInsertFormPage(component, reports) {
    const fields = [
      {
        name: 'fazenda',
        label: 'Fazenda',
        componentType: 'input',
      },
      {
        name: 'nomeConsultor',
        label: 'Consultor',
        componentType: 'input',
      },
      ...component.fields,
    ]

    const headers = fields.map(field => field.label)

    const values = []

    reports.forEach(report => {
      let value = this.getValue(component.name, report)

      if (!value) {
        return
      }

      value = JSON.parse(value)

      value.forEach(item => {
        const tmp = [
          this.getValue(fields[0].name, report),
          this.getValue(fields[1].name, report),
        ]

        component.fields.forEach(field => {
          tmp.push(item[field.name])
        })

        values.push(tmp)
      })
    })

    const ws = XLSX.utils.aoa_to_sheet([headers, ...values])
    this.styleHeaders(ws, headers)

    const label = this.transformaLabel(component.label)

    XLSX.utils.book_append_sheet(this.wb, ws, label)
  }

  transformaLabel(label) {
    let string = label.toLowerCase()

    if (string.includes('identificação das ')) {
      string = string.replace('identificação das ', '')
    }

    if (string.includes('identificação da ')) {
      string = string.replace('identificação da ', '')
    }

    string = string.charAt(0).toUpperCase() + string.slice(1)

    if (string.length > 31) {
      string = string.slice(0, 31)
    }

    return string
  }
}
