var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex"},[_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12","sm":"6","lg":"4","align":"center"}},[_c('span',{staticClass:"key-title"},[_vm._v("Conformidades obrigatórias: ")]),_c('section',[_c('g-chart',{attrs:{"type":"PieChart","data":[
          ['Categoria', 'Quantidade'],
          ['Realizado', parseInt(_vm.getValue('relevancia_1'))],
          ['Não realizado', 100 - parseInt(_vm.getValue('relevancia_1'))],
        ],"options":_vm.chartOptionsObrigatorio,"create-chart":_vm.drawPieChart}}),_c('p',[_vm._v(_vm._s(_vm.getValue('relevancia_1') + '%'))])],1)]),_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12","sm":"6","lg":"4","align":"center"}},[_c('span',{staticClass:"key-title"},[_vm._v("Conformidades relevantes:")]),_c('section',[_c('g-chart',{attrs:{"type":"PieChart","data":[
          ['Categoria', 'Quantidade'],
          ['Conforme', parseInt(_vm.getValue('relevancia_2'))],
          ['Não Conforme', 100 - parseInt(_vm.getValue('relevancia_2'))],
        ],"options":_vm.chartOptionsRelevante,"create-chart":_vm.drawPieChart}}),_c('p',[_vm._v(_vm._s(_vm.getValue('relevancia_2') + '%'))])],1)]),_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12","sm":"6","lg":"4","align":"center"}},[_c('span',{staticClass:"key-title"},[_vm._v("Conformidades aceitáveis:")]),_c('section',[_c('g-chart',{attrs:{"type":"PieChart","data":[
          ['Categoria', 'Quantidade'],
          ['Realizado', parseInt(_vm.getValue('relevancia_3'))],
          ['Não realizado', 100 - parseInt(_vm.getValue('relevancia_3'))],
        ],"options":_vm.chartOptionsAceitavel,"create-chart":_vm.drawPieChart}}),_c('p',[_vm._v(_vm._s(_vm.getValue('relevancia_3') + '%'))])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }