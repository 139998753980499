function OnylPie({ value, color, key }) {
  const html = `
    <div class="pie-wrapper progress-${parseInt(
      value.toString(),
    )}-${key} style-2">
      <span class="label-graph">${parseInt(
        value.toString(),
      )}<span class="smaller">%</span></span>
      <div class="pie">
        <div class="left-side half-circle"></div>
        <div class="right-side half-circle"></div>
      </div>
      <div class="shadow"></div>
    </div>
  `
  const css = generateCSSClass(value, color, key)
  return { html, css }
}

function generateCSSClass(value, color, key) {
  if (value <= 50) {
    const percentage = value * 3.6
    return `
      .pie-wrapper.progress-${parseInt(
        value.toString(),
      )}-${key} .pie .left-side {
        transform: rotate(${percentage}deg);
      }
      .pie-wrapper.progress-${parseInt(
        value.toString(),
      )}-${key} .pie .right-side {
        display: none;
      }

      .pie-wrapper.progress-${parseInt(
        value.toString(),
      )}-${key} .pie .half-circle {
        border-color: ${color};
      }
    `
  }

  if (value > 50 && value <= 75) {
    return `
    .pie-wrapper.progress-${parseInt(value.toString())}-${key} .pie {
      clip: rect(auto, auto, auto, auto);
    }
    .pie-wrapper.progress-${parseInt(
      value.toString(),
    )}-${key} .pie .half-circle {
      border-color: ${color};
    }
    .pie-wrapper.progress-${parseInt(value.toString())}-${key} .pie .left-side {
      transform: rotate(calc(${parseInt(value.toString())} * 3.6deg));
    }
    .pie-wrapper.progress-${parseInt(
      value.toString(),
    )}-${key} .pie .right-side {
      transform: rotate(calc(75 * 2.4deg));
    }
    `
  }

  if (value > 75) {
    return `
    .pie-wrapper.progress-${parseInt(value.toString())}-${key} .pie {
      clip: rect(auto, auto, auto, auto);
    }
    .pie-wrapper.progress-${parseInt(
      value.toString(),
    )}-${key} .pie .half-circle {
      border-color: ${color};
    }
    .pie-wrapper.progress-${parseInt(value.toString())}-${key} .pie .left-side {
      transform: rotate(calc(${parseInt(value.toString())} * 3.6deg));
    }
    .pie-wrapper.progress-${parseInt(
      value.toString(),
    )}-${key} .pie .right-side {
      transform: rotate(180deg);
    }
    `
  }
}

const OnlyPieStyles = `
* {
  box-sizing: border-box;
}

.set-size {
  font-size: 10em;
}

.label-graph {
  font-size: 16px;
}

.pie-wrapper {
  height: 1em;
  width: 1em;
  float: left;
  margin: 15px;
  position: relative;
  font-size: 10em;
}
.pie-wrapper .pie {
  height: 100%;
  width: 100%;
  clip: rect(0, 1em, 1em, 0.5em);
  left: 0;
  position: absolute;
  top: 0;
}
.pie-wrapper .pie .half-circle {
  height: 100%;
  width: 100%;
  border: 0.1em solid #3498db;
  border-radius: 50%;
  clip: rect(0, 0.5em, 1em, 0);
  left: 0;
  position: absolute;
  top: 0;
}
.pie-wrapper .label-graph {
  background: #34495e;
  border-radius: 50%;
  bottom: 0.4em;
  color: #ecf0f1;
  cursor: default;
  display: block;
  font-size: 0.25em;
  left: 0.2em;
  line-height: 2.8em;
  position: absolute;
  right: 0.4em;
  text-align: center;
  top: 0.4em;
}
.pie-wrapper .label-graph .smaller {
  color: #bdc3c7;
  font-size: 0.65em;
  padding-bottom: 20px;
  vertical-align: super;
}
.pie-wrapper .shadow {
  height: 100%;
  width: 100%;
  border: 0.1em solid #bdc3c7;
  border-radius: 50%;
}
.pie-wrapper.style-2 .label-graph {
  background: none;
  color: #7f8c8d;
}
.pie-wrapper.style-2 .label-graph .smaller {
  color: #bdc3c7;
}
`

export { OnlyPieStyles, OnylPie }
