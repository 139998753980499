export const EXCLUDED_FIELDS = [
  'assinaturaResponsavel',
  'assinaturaProdutor',
  'assinaturaProdutorOuResponsavel',
  'assinaturaConsultor',
  'historicoProducaoLeiteiraForm',
  'producaoLeiteiraMediaRebanhoForm',
  'historicoValorRecebidoLitroLeiteForm',
]

export const PAGE_FIELDS = [
  {
    key: 'historicoProducaoLeiteiraForm',
    label: 'Histórico de produção',
    headers: ['Produtor', 'Histórico de produção leiteira', 'Ano'],
    params: ['historicoProducaoLeiteira', 'periodoProducaoLeiteiraInicial'],
  },
  {
    key: 'producaoLeiteiraMediaRebanhoForm',
    label: 'Produção leiteira média',
    headers: ['Produtor', 'Produção leiteira média por rebanho', 'Ano'],
    params: ['producaoLeiteiraMediaRebanho', 'producaoLeiteiraInicial'],
  },
  {
    key: 'historicoValorRecebidoLitroLeiteForm',
    label: 'Histórico de valor por litro',
    headers: ['Produtor', 'Histórico de valor por litro', 'Ano'],
    params: [
      'historicoValorRecebidoLitroLeite',
      'historicoValorRecebidoLitroLeitePeriodoInicial',
    ],
  },
]

export const EXCLUDED_COMPONENTS = [
  'btnAssinatura',
  'multipleImageSelect',
  'dynamicText',
  'groupField',
  'groupCheckbox',
  'text',
  'multiInsertForm',
]
