import moment from 'moment'
import { OnlyPieStyles, OnylPie } from './components/Charts/index.js'
import { MultiplesImages } from './components/MutipleImages/index.js'
import { signature } from './components/Signature/index.js'
import { draftStyle, styles } from './styles.js'

export class ReportPrintBase {
  constructor() {
    this.imageHeader = undefined
    this.imageHeaderRight = undefined
    this.title = undefined
    this.header = ''
    this.content = ''
    this.footer = ''
    this.footerHtml = ''
    this.externalStyles = ''
    this.isDrift = false
    this.dataGroups = []
    this.document = window.document
  }

  setFooter(text) {
    this.footer = text
  }

  async loadHtmlBase() {
    this.loadHeader()
    this.loadFooter()

    if (this.content === '') {
      throw new Error(
        'Nenhum conteúdo adicionado ao relatório. Use a função loadHtml das classes filhas para adicionar o conteúdo.',
      )
    }

    return `<!DOCTYPE html>
      <html lang="pt-br">
        <head>
          <meta charset="UTF-8">
          <meta http-equiv="X-UA-Compatible" content="IE=edge">
          <meta name="viewport" content="width=device-width,initial-scale=1,user-scalable=yes" />
          <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet">
          <title>Relatório MilkPlan Report</title>
          ${this.loadStyle()}
        </head>
        <body>
          <div class="body">
          ${this.header}
          ${this.content}
          ${this.footerHtml}
          </div>
        </body>
      </html>`
  }

  setImageHeader(imageHeader) {
    this.imageHeader = imageHeader
  }

  setTitle(title) {
    this.title = title
  }

  setContent(content) {
    this.content = content
  }

  loadStyle() {
    return `
      <style type="text/css">
        ${styles}
        ${this.externalStyles}
        ${this.isDrift ? draftStyle : ''}
      </style>`
  }

  loadHeader() {
    this.header = `
      <div class="header">
        <div class="logo">
          <img src="${this.imageHeader}" width="100" alt="Logo"/>
        </div>
        <div class="coluna title">
          <h2>${this.title}</h2>
        </div>
        ${this.imageHeaderRight || ''}
      </div>`
  }

  setImageHeaderRight(logo) {
    this.imageHeaderRight = `
      <div class="logoagroindustria">
        <img src="${logo}" width="100" alt="Logo"/>
      </div>`
  }

  loadFooter() {
    const now = new Date()
    const str = `Gerado em ${moment(now).format('DD/MM/YYYY, HH:mm')}`

    this.footerHtml = `
      <div class="divFooter"><em>${this.footer || str}</em></div>`
  }

  addStartLine(props = '') {
    return `<div class="linha ${props}">`
  }

  addStartLineRow(props = '') {
    return `<div class="linha linha_row ${props} bb">`
  }

  addEndLine() {
    return `</div>`
  }

  addStartGroup() {
    return `<div class="group">`
  }

  addEndGroup() {
    return `</div>`
  }

  addColumn(params) {
    const { value, type } = params

    if (value === '-') return ''

    if (!params.value) params.value = ''

    switch (type) {
      case 'assinatura':
      case 'signatureWithName':
        return this.generateSignatureContent(params)
      case 'multipleImageSelect':
        return this.generateMultipleImagesContent(params)
      case 'OnlyCircleChart':
        return this.generateOnlyCircleChartContent(params)
      case 'selection':
      case 'selectionOption':
        return this.generateOnlySelectionContent(params)
      default:
        return this.generateDefaultContent(params)
    }
  }

  generateOnlySelectionContent(data) {
    const { label, columns, props } = data

    if (!columns) {
      return this.generateDefaultContent(data)
    }

    const renderColumns = columns => {
      if (!columns) return []

      return columns.map(column => {
        if (column.value && column.type === 'selection') {
          return `<div class="coluna ${column.props || ''}"><span>${
            column.label
          }</span><div>${renderColumns(column.columns).join('')}</div></div>`
        }
        if (column.value && column.type === 'selectionOption') {
          return `<div class="coluna ${column.props || ''}"><span>${
            column.label
          }</span><div>${renderColumns(column.columns).join('')}</div></div>`
        }
        if (column.columns && column.columns.length > 0) {
          return renderColumns(column.columns).join('')
        }
        return this.addColumn(
          column.label,
          column.value,
          column.key,
          column.type || '',
          column.prefixo || '',
          column.sufixo || '',
          column.props,
        )
      })
    }

    return `<div class="coluna ${props || ''}">${
      label !== '-' ? `<span>${label}</span>` : ''
    }<div>${renderColumns(columns).join('')}</div></div>`
  }

  generateSignatureContent(data) {
    return signature(data) || ''
  }

  generateMultipleImagesContent(data) {
    return MultiplesImages(data) || ''
  }

  generateOnlyCircleChartContent(data) {
    const { label, value, fieldName, sufixo, props } = data

    const generatedChart = OnylPie({
      value: Number(value),
      color: sufixo,
      key: fieldName,
    })

    this.externalStyles += OnlyPieStyles
    this.externalStyles += generatedChart.css

    return `<div class="coluna ${props || ''} vertical-line">${
      label !== '-'
        ? `<div style="margin-bottom: 10px;"><span>${label}</span></div>`
        : ''
    }${generatedChart.html}</div>`
  }

  generateDefaultContent(data) {
    const { value, label, fieldName, props, html } = data

    if (value === 'confirm' || value === 'cancel') {
      return this.generateConfirmCancelContent(data)
    }

    return `
      <div class="coluna ${props || ''}">
      ${label !== '-' ? `<span>${label}</span>` : ''}
      <b class="dont-break-out" style="word-wrap: break-word;" id="${fieldName}">${value}</b>
      ${html || ''}
      </div>
    `
  }

  generateConfirmCancelContent(data) {
    const { label, value, fieldName, type, prefixo, sufixo, props } = data

    return `<div class="conform coluna ${props || ''}">${
      label !== '-' ? `<span class="label">${label}</span>` : ''
    }${
      type !== 'checkbox'
        ? ''
        : `<span id="${fieldName}">${prefixo}${sufixo}</span>`
    }${
      value === 'cancel'
        ? '<span class="icon icon-x">&#x2715;</span>'
        : '<span class="icon">&#10003;</span>'
    }</div>`
  }

  addSubtitle(value) {
    return `<div class="subtitle"><h3>${value}</h3></div>`
  }

  hasValueInColumn(line) {
    let hasValue = false
    line.columns.forEach(column => {
      if (column.value && column.value !== '') {
        hasValue = true
      }
      if (column.lines && column.lines.length > 0) {
        column.lines.forEach(item => {
          let hasVal = this.hasValueInColumn(item)
          if (hasVal) hasValue = true
        })
      }
    })
    return hasValue
  }

  addTableHeader(field, header, values, index) {
    let contentHtml = ''

    contentHtml += this.addColumn({
      label: `<b>${field.label}: </b>`,
      value: '',
      type: field.componentType,
      fieldName: field.name,
      prefix: '',
      suffix: '',
      props: field.props,
    })

    const tableElement = this.createTableElement(header, values)
    contentHtml += tableElement.outerHTML

    const container = this.createContainerElement(contentHtml, index)

    return container.outerHTML
  }

  // createTableElement(header, values) {
  //   const table = this.document.createElement('table')

  //   const headerRow = this.document.createElement('tr')
  //   header.forEach(item => {
  //     const th = this.document.createElement('th')
  //     th.innerHTML = item.label
  //     headerRow.appendChild(th)
  //   })
  //   table.appendChild(headerRow)

  //   values?.forEach(value => {
  //     const row = this.document.createElement('tr')
  //     header.forEach(item => {
  //       const td = this.document.createElement('td')
  //       td.innerHTML = value[item.key]
  //       row.appendChild(td)
  //     })
  //     table.appendChild(row)
  //   })
  //   return table
  // }

  createTableElement(header, values) {
    const table = this.document.createElement('table')

    const headerRow = this.document.createElement('tr')
    header.forEach(item => {
      const th = this.document.createElement('th')
      th.innerHTML = item.label
      headerRow.appendChild(th)
    })
    table.appendChild(headerRow)

    if (header.length > 1) {
      values?.forEach(value => {
        const row = this.document.createElement('tr')
        header.forEach(item => {
          const td = this.document.createElement('td')
          td.innerHTML = value[item.key]
          row.appendChild(td)
        })
        table.appendChild(row)
      })
    } else {
      const columnsPerRow = 4

      header.forEach((cabecalho, index) => {
        for (let i = 0; i < values.length; i += columnsPerRow) {
          const row = this.document.createElement('tr')
          const td = this.document.createElement('td')
          for (let j = 0; j < columnsPerRow; j++) {
            const span = this.document.createElement('span')
            span.classList.add('box')
            if (!values[i + j]) continue
            span.innerHTML = values[i + j]
              ? values[i + j][header[index]?.key]
              : ''
            td.appendChild(span)
          }
          row.appendChild(td)
          table.appendChild(row)
        }
      })
    }

    return table
  }

  createContainerElement(contentHtml, index) {
    const container = this.document.createElement('div')
    container.classList.add('linha', 'linha_row')
    if (index === 0) {
      container.classList.add('bt')
    }
    container.innerHTML = contentHtml
    return container
  }
}
