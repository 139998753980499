<template>
  <v-col
    v-if="isShowInput()"
    :cols="getCols"
    sm="6"
    lg="6"
    xl="4"
    class="col-bordered"
  >
    <span class="key-title">{{ getLabel(component.label) }} </span>
    <span v-if="component.html" v-html="getValue(component.name)"></span>
    <span v-else class="value-title">{{ getValue(component.name) }}</span>
  </v-col>
  <v-col
    v-else-if="isExistsUpdate()"
    :cols="getCols"
    sm="6"
    lg="6"
    xl="4"
    class="col-bordered"
  >
    <span class="key-title">{{ getLabel(component.label) }} </span>
    <span class="value-title">
      <UpdateFields
        v-if="updatedField"
        :id="getValue('id')"
        :component="component"
        :title="component.label"
        :field="{
          key: component.name,
          property: component.name,
          type: updatedField.type,
          prefix: updatedField.prefix,
        }"
      />
    </span>
  </v-col>
</template>
<script>
import { mapGetters } from 'vuex'
import UpdateFields from '../../../../components/reports/UpdateFields.vue'
import updateFieldsItens from '../../../../services/StructureReport/updateFieldsItens'
export default {
  name: 'TextInput',

  components: { UpdateFields },

  props: {
    component: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      updatedField: null,
    }
  },

  computed: {
    ...mapGetters('report', ['getValue']),
    getCols() {
      if (this.component.cols) {
        return this.component.cols
      }

      if (this.component.componentType === 'textArea') {
        return 12
      }

      if (
        this.component.componentType === 'datePiker' ||
        this.component.componentType === 'timePiker'
      ) {
        return 4
      }

      return 6
    },
  },

  created() {
    this.updatedField = updateFieldsItens.find(
      item => item.key === this.component.name,
    )
  },

  methods: {
    getLabel(string) {
      if (!string) return ''

      string = string[string.length - 1] == ' ' ? string.slice(0, -1) : string
      string = string[string.length - 1] == '?' ? string.slice(0, -1) : string

      return string[string.length - 1] == ':' ? string : `${string}: `
    },

    isExistsUpdate() {
      return !!updateFieldsItens.find(item => item.key === this.component.name)
    },

    isShowInput() {
      return Boolean(
        this.getValue(this.component.name)?.length &&
          this.getValue(this.component.name) != '0' &&
          !this.isExistsUpdate(),
      )
    },
  },
}
</script>
