/* eslint-disable */
import HtmlToCanvas from 'html2canvas'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
import { ENV_APP } from '../../../../env'
import { monthsNames } from '../../../utils/dashboard/dashboard'

export const headerDoc = ({ doc, config }) => {
  const { title, logo, startY } = config

  const headerLogo = new Image()

  headerLogo.src = logo

  doc.addImage(headerLogo, 'PNG', 13, 10, 20, 20)
  doc.setFontSize(config.largefontSize).setFont('Courier', 'bold')

  doc.text(
    title,
    config.tableLimit / 2 - doc.getTextWidth(title) / 2 + 20,
    20 + 30,
  )

  doc.setFontSize(config.fontSize).setFont('Courier', 'normal')
}

export const addTables = ({ doc, tables, header }) => {
  let startY = 20

  tables.forEach(table => {
    doc.autoTable({
      startY,
      html: table,
      headStyles: { fillColor: [31, 73, 125] },
    })

    startY = doc.autoTable.previous.finalY + 20
  })
}

export const create = async ({ config, tables, images }) => {
  const doc = new jsPDF('l')

  let startY = 0

  config.startY = startY
  config.tableLimit =
    doc.internal.pageSize.width - config.margin - config.margin
  config.heightLimit = doc.internal.pageSize.height - config.margin

  headerDoc({ doc, config })
  await render({ doc, config, images })

  config.startY = 100

  addTables({ doc, tables })

  footer({ doc, config })

  save({ doc, filename: config.title })
}

export const footer = ({ doc, config }) => {
  const { heightLimit } = config
  const pageCount = doc.internal.getNumberOfPages()
  const docWidth = doc.internal.pageSize.width
  const date = footerTitle()

  for (let i = 0; i < pageCount; i++) {
    doc.setPage(i)
    let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber
    doc.setFontSize(12)
    const text =
      date +
      '       ' +
      `${ENV_APP.relUri}` +
      '        Pag. ' +
      pageCurrent +
      ' de ' +
      pageCount

    doc.setTextColor(112, 112, 112)
    doc.setFontSize(9)
    doc.text(text, docWidth / 2, heightLimit + 15, 'center')
  }
}

export const footerTitle = () => {
  const now = new Date()
  const str =
    'Impresso em ' +
    now.getDate() +
    ' de ' +
    monthsNames[now.getMonth()] +
    ' de ' +
    now.getFullYear() +
    ' às ' +
    moment(now).format('DD/MM/YYYY')

  return str
}

export const save = ({ doc, filename = '' }) => {
  const name = filename.replace(/\s/g, '-').toLowerCase()
  filename = `${name}-${new Date().getTime()}.pdf`

  const result = doc.save(filename)

  return result
}

export const render = async ({ doc, images, config }) => {
  config.startY = 60
  let first = true

  for (const data of images.values()) {
    let text = data.title

    const canvas = await HtmlToCanvas(data.image)

    const dataURL = canvas.toDataURL('image/png')

    doc
      .setFont('Courier', 'bold')
      .setFontSize(config.mediumFontSize)
      .text(text, config.startX, config.startY)
      .setFont('Courier', 'normal')
      .setFontSize(config.fontSize)

    const pageWidth = doc.internal.pageSize.getWidth()
    const pageHeight = doc.internal.pageSize.getHeight()

    if (first) {
      const marginX = (pageWidth - 170) / 2
      const marginY = (pageHeight - 115) / 2

      doc.addImage(dataURL, 'PNG', marginX, marginY + 20, 220, 115)
      config.startY = 20
      first = false
    } else {
      const marginX = (pageWidth - 220) / 2
      const marginY = (pageHeight - 130) / 2
      doc.addImage(dataURL, 'PNG', marginX, marginY, 220, 130)
    }

    doc.addPage()
  }
}
