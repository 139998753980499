<template>
  <v-card class="mx-2">
    <v-tabs background-color="">
      <v-tab @click="tabs = 0"
        >Relatórios
        <v-icon>mdi-file-document</v-icon>
      </v-tab>
      <v-tab @click="tabs = 1"
        >Rascunhos

        <v-icon>mdi-file</v-icon>
      </v-tab>
      <v-tab v-if="isShowDashboard" @click="tabs = 2">
        Dashboard
        <v-icon>mdi-view-dashboard</v-icon>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <ReportLists />
      </v-tab-item>

      <v-tab-item>
        <ReportLists :draft="true" />
      </v-tab-item>

      <v-tab-item v-if="isShowDashboard">
        <Dashboard />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import ReportLists from './ReportLists.vue'
import Dashboard from './dashboards/index.vue'

export default {
  name: 'Reports',

  components: {
    ReportLists,
    Dashboard,
  },

  data() {
    return {
      tabs: 0,
      reportTypeId: 1,
    }
  },

  computed: {
    isShowDashboard() {
      return (
        this.$user.get().role === 'admin' ||
        this.$user.get().role === 'gestor' ||
        this.$user.get().role === 'consultoria'
      )
    },
  },
}
</script>

<style styled>
.v-tab--active .v-icon {
  color: var(--primary);
}
</style>
