export default [
  {
    key: 'cppMedia',
    label: 'CPP Media',
    type: 'number',
    prefix: 'UFC/ml * 10³',
  },
  {
    key: 'ccsMedia',
    label: 'CCS média do último mês',
    type: 'number',
    prefix: 'céls/ml * 10³',
  },
  {
    key: 'gorduraMediaUltimoMes',
    label: 'Gordura média do último mês',
    type: 'number',
    prefix: '%',
  },
  {
    key: 'proteinaMediaUltimoMes',
    label: 'Proteína média do último mês',
    type: 'number',
    prefix: '%',
  },
]
