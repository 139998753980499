<template>
  <v-col class="col-bordered" cols="12">
    <span :class="`mdi ${icon()}`"></span>
    <span class="value-title">
      {{ component.label }}
    </span>
    <v-col cols="12">
      <RenderComponents
        v-for="field in component.fields"
        :key="field.name"
        :component="field"
      ></RenderComponents>
    </v-col>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Conform',

  components: {
    RenderComponents: () => import('./RenderComponents.vue'),
  },

  props: {
    component: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters('report', ['getValue']),

    showSubItems() {
      return true
    },
  },

  methods: {
    icon() {
      if (this.getValue(this.component.name) == 'cancel') {
        return 'mdi-close-outline'
      }

      if (this.getValue(this.component.name) == 'confirm') {
        return 'mdi-check-outline'
      }
    },
  },
}
</script>

<style></style>
