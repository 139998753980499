function MultiplesImages({ label, value }) {
  if (!value) return

  let content = ''

  value.forEach((element, index) => {
    let props = ''

    if (index % 2 === 1) {
      props = 'bl'
    }

    content += `
    <div class="column-image coluna ${props}">
      <img class="image" src="${element.base64}" height="400" width="300" alt="" />
    </div>
`
  })

  content = GridImages(content, label)

  return content
}

function GridImages(images, title) {
  if (title.includes('Selecione algumas ')) {
    title = title.replace('Selecione algumas ', '')
    title = title.charAt(0).toUpperCase() + title.slice(1)
  }

  return `
  <div style="width: 100%">
    <div class="bb" style="padding: 10px 5px">
      <b style="margin-left: 0.7rem">${title || ''}</b>
    </div>
    <div style="display: flex">
      <div class="multiples-images">
        ${images}
      </div>
    </div>
  </div>
  `
}

export { GridImages, MultiplesImages }
