<template>
  <v-col class="col-bordered" cols="12">
    <span class="key-title">{{ component.label }}</span>

    <RenderComponents
      v-for="field in component.fields"
      :key="field.name"
      :component="field"
    />
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Selection',

  components: {
    RenderComponents: () => import('./RenderComponents.vue'),
  },

  props: {
    component: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  computed: {
    ...mapGetters('report', ['getValue']),
  },
}
</script>

<style></style>
