var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"justify":"space-around"}},[_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"1000","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({class:_vm.reportItem.ValidacaoId === 'Não validado'
            ? 'light-blue darken-4 white--text'
            : _vm.reportItem.ValidacaoId === 'Válido'
            ? 'teal lighten-1 white--text'
            : _vm.reportItem.ValidacaoId === 'Inconsistente'
            ? 'red lighten-1 white--text'
            : _vm.reportItem.ValidacaoId === 'Justificado'
            ? 'teal darken-4 white--text'
            : '-',attrs:{"rounded":"","small":"","plain":"","text":""},on:{"click":function($event){_vm.modal = true}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.reportItem.ValidacaoId)+" ")])]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v("Histórico de Validação")]),_c('v-card',[(_vm.loading)?_c('base-loading'):_c('div',[_c('v-data-table',{attrs:{"items":_vm.statusData,"headers":_vm.headers,"item-class":_vm.itemRowBackground},scopedSlots:_vm._u([{key:`item.Data`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.moment(item.Data).format('DD/MM/YYYY HH:mm:ss'))+" ")]}},{key:`item.Status`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.typeValidation(item.Status))+" ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
                      _vm.$user.get().role === 'admin' ||
                      _vm.$user.get().role === 'gestor' ||
                      (_vm.statusData.length > 0 &&
                        _vm.statusData[0].Status == 2 &&
                        _vm.$user.get().role === 'consultor')
                    )?_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.formTitle)+" ")]):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticStyle:{"font-size":"18px"}},[_vm._v(" Exclusão de Avaliação ")]),_c('br')])],1),_c('v-card',[_c('v-card-title',{staticClass:"text-h4"},[_vm._v("Você tem certeza que deseja excluir esse item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","loading":_vm.salvando},on:{"click":_vm.deleteItemConfirm}},[_vm._v("Sim")]),_c('v-btn',{attrs:{"color":"error","disabled":_vm.salvando},on:{"click":_vm.closeDelete}},[_vm._v("Não")]),_c('v-spacer')],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v(" "+_vm._s(_vm.formTitle)+" ")])])],1),_c('v-card-text',[_c('v-container',[_c('v-row',[(
                            _vm.$user.get().role === 'admin' ||
                            _vm.$user.get().role === 'gestor'
                          )?_c('v-col',{staticStyle:{"padding":"0px"},attrs:{"cols":"12","md":"12"}},[_c('v-select',{attrs:{"items":_vm.tipos,"label":"Status da Avaliação","rules":_vm.requiredRules},model:{value:(_vm.lancamento.tipo),callback:function ($$v) {_vm.$set(_vm.lancamento, "tipo", $$v)},expression:"lancamento.tipo"}})],1):_vm._e(),_c('v-col',{staticStyle:{"padding-top":"0px","padding-bottom":"0px"},attrs:{"cols":"12","sm":"6","md":"12"}},[_c('v-textarea',{attrs:{"filled":"","label":"Descrição da Atualização","rules":_vm.requiredRules},model:{value:(_vm.lancamento.descricao),callback:function ($$v) {_vm.$set(_vm.lancamento, "descricao", $$v)},expression:"lancamento.descricao"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"success","width":"100","loading":_vm.salvando},on:{"click":_vm.save}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v(" mdi-checkbox-marked-circle ")]),_vm._v(" Salvar ")],1),_c('v-btn',{attrs:{"color":"error","width":"120","disabled":_vm.salvando},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v(" mdi-minus-circle ")]),_vm._v(" Cancelar ")],1)],1)],1)],1)],1)]},proxy:true},(
              this.$user.get().role === 'admin' ||
              this.$user.get().role === 'gestor'
            )?{key:`item.actions`,fn:function({ item }){return [_c('v-icon',{attrs:{"color":"red darken-3","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}:null,{key:"no-data",fn:function(){return [_c('span',{staticStyle:{"font-size":"15px"}},[_vm._v(" Relatório sem atualizações até o momento. ")])]},proxy:true}],null,true)})],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.modal = false}}},[_vm._v("Fechar")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }