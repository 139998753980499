import { ENV_APP } from '../../../../env'
import reports from '../../api/reports'
import { ReportPrint } from './ReportPrint'

export default class PDFGenerator {
  constructor(structure, layout, report, validation) {
    this.layout = layout
    this.report = JSON.parse(JSON.stringify(report))
    this.structure = structure
    this.validation = validation
    this.reportPrint = new ReportPrint(
      this.structure,
      this.report,
      this.layout,
      this.validation,
    )
  }

  // eslint-disable-next-line no-unused-vars
  async create(filename = 'Relatório', show = false) {
    const logo = await this.loadAssetsToBase64(this.getLogo())
    this.reportPrint.setImageHeader(logo)

    const agroindustryLogo = this.reportPrint.getFieldItem('agroindustryLogo')
    this.reportPrint.setImageHeaderRight(agroindustryLogo?.value)

    await this.processSignatures()
    await this.processImages()

    const html = await this.reportPrint.createHtmlStructure()

    if (show) {
      this.printPDF(html)
    } else {
      await this.generateAndDownload(html, filename)
    }
  }

  loadAssetsToBase64(src) {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.crossOrigin = 'Anonymous'
      img.onload = () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        canvas.width = img.width
        canvas.height = img.height
        ctx.drawImage(img, 0, 0)
        resolve(canvas.toDataURL('image/png'))
      }
      img.onerror = reject
      img.src = src
    })
  }

  getLogo() {
    if (ENV_APP.slug === 'milk-plan-report') {
      return require('../../../../public/img/assist-logo.png')
    }

    return require('../../../../public/img/vigor-assist.png')
  }

  async processSignatures() {
    const signatures = this.collectFieldsByType('btnAssinatura')

    for (const signature of signatures) {
      const id = this.reportPrint.getFieldValue(signature.name)
      if (!id) continue

      if (id.length > 36) {
        this.reportPrint.setFieldValue(signature.name, id)
        continue
      }

      try {
        const base64 = await this.reportPrint.getBase64Image(
          `${ENV_APP.apiUri}/signature/${id}`,
        )
        this.reportPrint.setFieldValue(signature.name, base64)
      } catch {
        this.reportPrint.setFieldValue(signature.name, '')
      }
    }
  }

  async processImages() {
    const imageFields = this.collectFieldsByType('multipleImageSelect')

    for (const field of imageFields) {
      try {
        const rawValue = this.reportPrint.getFieldValue(field.name)
        if (!rawValue) continue

        const images = JSON.parse(rawValue)
        await Promise.all(
          images.map(async image => {
            image.base64 = await this.reportPrint.getBase64Image(
              `${ENV_APP.apiUri}/download-image/${image.id}`,
            )
          }),
        )

        this.reportPrint.setFieldValue(field.name, JSON.stringify(images))
      } catch {
        this.reportPrint.setFieldValue(field.name, '')
      }
    }
  }

  collectFieldsByType(type) {
    const fields = []
    this.reportPrint.recursive(field => {
      if (field.componentType === type) {
        fields.push(field)
      }
    })
    return fields
  }

  printPDF(html) {
    const iframe = document.createElement('iframe')
    iframe.style.display = 'none'
    iframe.src = URL.createObjectURL(new Blob([html], { type: 'text/html' }))
    document.body.appendChild(iframe)
    iframe.contentWindow.print()

    setTimeout(() => {
      document.body.removeChild(iframe)
    }, 1000)
  }

  async generateAndDownload(html, filename) {
    const { data } = await reports.generatePdf(html)
    const a = document.createElement('a')
    a.href = window.URL.createObjectURL(data)
    a.download = `${filename}.pdf`
    document.body.appendChild(a)
    a.click()
    a.remove()
  }
}
