<template>
  <v-card-text>
    <v-row class="pb-4 mt-1" justify="center">
      <g-chart
        v-if="isChartReady"
        id="chart-rebanho"
        type="PieChart"
        style="height: 35vh; width: 79vh"
        :data="[
          ['Categoria', 'Quantidade'],
          ['Vacas em lactação', report.VacasLactacao],
          ['Vacas secas', report.VacasSecas],
          ['Novilhas', report.Novilhas],
          ['Machos', report.Machos],
          ['Recria', report.Recria],
          ['Aleitamento', report.Aleitamento],
        ]"
        :options="chartOptions"
      />
    </v-row>
  </v-card-text>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'CompisicaoDoRebanhoGraph',

  data() {
    return {
      chartOptions: {
        legend: {
          position: 'bottom',
          align: 'center',
        },
        chartArea: { height: '85%', width: '100%' },
        tooltip: { isHtml: true },
      },

      report: {
        VacasLactacao: 0,
        VacasSecas: 0,
        Novilhas: 0,
        Machos: 0,
        Recria: 0,
        Aleitamento: 0,
      },

      isChartReady: false,

      chartData: [],
    }
  },

  computed: {
    ...mapGetters('report', ['getValue']),
  },

  mounted() {
    this.initializeChartData()
  },
  methods: {
    initializeChartData() {
      this.report = {
        VacasLactacao: Number(this.getValue('vacasLactacao')) || 0,
        VacasSecas: Number(this.getValue('vacasSecas')) || 0,
        Novilhas: Number(this.getValue('qtdeNovilhas')) || 0,
        Machos: Number(this.getValue('qtdeMachos')) || 0,
        Recria: Number(this.getValue('qtdeRecria')) || 0,
        Aleitamento: Number(this.getValue('qtdeAleitamento')) || 0,
      }

      this.chartData = [
        ['Categoria', 'Quantidade'],
        ['Vacas em lactação', this.report.VacasLactacao],
        ['Vacas secas', this.report.VacasSecas],
        ['Novilhas', this.report.Novilhas],
        ['Machos', this.report.Machos],
        ['Recria', this.report.Recria],
        ['Aleitamento', this.report.Aleitamento],
      ]

      setTimeout(() => {
        this.isChartReady = true
      }, 500)
    },
  },
}
</script>

<style>
svg > g:last-child > g:last-child {
  pointer-events: none;
}
div.google-visualization-tooltip {
  pointer-events: none;
}
</style>
