<template>
  <v-col cols="12" sm="6" lg="6" xl="4" class="col-bordered">
    <span class="key-title">{{ getLabel(component.label) }} </span>
    <span class="value-title">{{ getValue(component.name) }}</span>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TextView',

  props: {
    component: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters('report', ['getValue']),
  },

  methods: {
    getLabel(string) {
      string = string[string.length - 1] == ' ' ? string.slice(0, -1) : string

      return string[string.length - 1] == ':' ? string : `${string}: `
    },
  },
}
</script>

<style></style>
