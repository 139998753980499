export const HeaderPDF = [
  {
    name: 'fazenda',
    type: 'default',
    label: 'Fazenda',
    placeholder: 'Selecione uma Fazenda',
    componentType: 'inputText',
  },
  {
    name: 'nomeConsultor',
    type: 'default',
    label: 'Consultor',
    placeholder: 'Consultor da fazenda',
    componentType: 'inputText',
  },
  {
    name: 'matricula',
    type: 'default',
    label: 'Matrícula',
    placeholder: 'Matrícula da fazenda',
    componentType: 'inputText',
  },
  {
    name: 'produtor',
    type: 'default',
    label: 'Produtor',
    placeholder: 'Produtor da fazenda',
    componentType: 'inputText',
  },
  {
    name: 'cidade',
    type: 'default',
    label: 'Cidade',
    placeholder: 'Cidade da fazenda',
    componentType: 'inputText',
  },
  {
    name: 'dataAtendimento',
    type: 'default',
    label: 'Data do Atendimento',
    placeholder: 'Data do atendimento',
    componentType: 'inputText',
  },
  {
    name: 'horaInicio',
    type: 'default',
    label: 'Hora de Início',
    placeholder: 'Hora de início do atendimento',
    componentType: 'inputText',
  },
  {
    name: 'horaTermino',
    type: 'default',
    label: 'Hora de Término',
    placeholder: 'Hora de término do atendimento',
    componentType: 'inputText',
  },
]
