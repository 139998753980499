<template>
  <InputText v-if="isValidInputText" :component="component" />

  <Checkbox
    v-else-if="component.componentType == 'checkbox'"
    :component="component"
  />

  <TextView
    v-else-if="component.componentType == 'text'"
    :component="component"
  />

  <GroupField v-else-if="isValidGroupField" :component="component" />

  <SomeCheckbox
    v-else-if="component.componentType == 'someCheckbox'"
    :component="component"
  />

  <MultipleImageSelect
    v-else-if="component.componentType == 'multipleImageSelect'"
    :component="component"
  />

  <MultiInsertForm
    v-else-if="component.componentType == 'multiInsertForm'"
    :component="component"
  />

  <Signature
    v-else-if="component.componentType == 'btnAssinatura'"
    :component="component"
  />

  <Conform
    v-else-if="component.componentType == 'confirmGroup'"
    :component="component"
  />

  <Selection v-else-if="isValidSelection" :component="component" />
</template>

<script>
import { mapGetters } from 'vuex'
import Checkbox from './Checkbox.vue'
import Conform from './Conform.vue'
import GroupField from './GroupField.vue'
import InputText from './InputText.vue'
import MultiInsertForm from './MultiInsertForm.vue'
import MultipleImageSelect from './MultipleImageSelect.vue'
import Selection from './Selection.vue'
import Signature from './Signature.vue'
import SomeCheckbox from './SomeCheckbox.vue'
import TextView from './TextView.vue'

export default {
  name: 'RenderComponents',

  components: {
    InputText,
    Checkbox,
    TextView,
    SomeCheckbox,
    MultipleImageSelect,
    MultiInsertForm,
    Signature,
    Conform,
    GroupField,
    Selection,
  },

  props: {
    component: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters('report', ['getValue']),

    isValidInputText() {
      return (
        this.component.componentType == 'inputText' ||
        this.component.componentType == 'datePiker' ||
        this.component.componentType == 'timePiker' ||
        this.component.componentType == 'textArea'
      )
    },

    isValidGroupField() {
      return (
        this.component.componentType == 'groupField' ||
        this.component.componentType == 'groupRadioButton' ||
        (this.component.componentType == 'groupCheckbox' &&
          this.component.name &&
          this.getValue(this.component.name) == '1')
      )
    },

    isValidSelection() {
      return (
        (this.component.componentType == 'selection' ||
          this.component.componentType == 'selectionOption' ||
          this.component.componentType == 'buttonSelect') &&
        this.getValue(this.component.name) == '1'
      )
    },
  },
}
</script>
