<template>
  <v-col v-if="getValue(component.name) == 1" cols="12" sm="12" lg="12" xl="4">
    <div class="col-bordered py-2 px-1" width="98%">
      <span class="key-title">{{ component.label }}</span>
    </div>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Checkbox',

  props: {
    component: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters('report', ['getValue']),
  },
}
</script>
