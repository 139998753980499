<template>
  <v-col cols="12">
    <span class="key-title px-1 py-1"
      >{{ component.placeholder ?? 'Imagens' }}:
    </span>
    <v-row class="col-bordered ml-1 mr-1">
      <v-col v-for="(imagem, index) in getImagens" :key="index" cols="6">
        <v-img
          v-if="imagem.id"
          :src="getImagem(imagem.id)"
          contain
          max-width="600px"
          max-height="600px"
          class="rounded elevation-3"
          :alt="component.placeholder ?? component.label"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'
import { ENV_APP } from '../../../../../env'

export default {
  name: 'MultipleImageSelect',

  props: {
    component: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters('report', ['getValue']),

    getImagens() {
      const value = this.getValue(this.component.name)

      if (!value) return []

      return JSON.parse(value)
    },
  },

  methods: {
    getImagem(idImagem) {
      if (idImagem.length > 36) return idImagem

      return `${ENV_APP.apiUri}/download-image/${idImagem}`
    },
  },
}
</script>
