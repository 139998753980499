<template>
  <v-col v-if="step.title.includes('Composição do rebanho')" cols="12">
    <CompisicaoDoRebanhoGraph />
  </v-col>
  <v-col
    v-else-if="step.title.includes('Boas práticas em qualidade do leite')"
    cols="12"
  >
    <ConformGraph />
  </v-col>
</template>

<script>
import CompisicaoDoRebanhoGraph from './CompisicaoDoRebanhoGraph.vue'
import ConformGraph from './ConformGraph.vue'

export default {
  name: 'GraphReport',

  components: {
    CompisicaoDoRebanhoGraph,
    ConformGraph,
  },

  props: {
    step: {
      type: Object,
      required: true,
    },
  },
}
</script>
