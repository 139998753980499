<template>
  <DashboardGeral v-if="reportTypeId == 1" />
  <DashboardCCS v-else-if="reportTypeId == 2" />
  <DashboardCPP v-else-if="reportTypeId == 3" />
  <DashboardEvolui v-else-if="reportTypeId == 4" />
  <DashboardEducampo v-else-if="reportTypeId == 9" />
  <DashboardSolidos v-else-if="reportTypeId == 13" />
</template>

<script>
import DashboardCCS from './DashboardCCS.vue'
import DashboardCPP from './DashboardCPP.vue'
import DashboardEducampo from './DashboardEducampo.vue'
import DashboardEvolui from './DashboardEvolui.vue'
import DashboardGeral from './DashboardGeral.vue'
import DashboardSolidos from './DashboardSolidos.vue'

export default {
  name: 'Dashboard',
  components: {
    DashboardGeral,
    DashboardCCS,
    DashboardCPP,
    DashboardEvolui,
    DashboardEducampo,
    DashboardSolidos,
  },

  data() {
    return {
      reportTypeId: null,
    }
  },

  watch: {
    $route() {
      this.reportTypeId = this.$route.params.id
    },
  },

  created() {
    this.reportTypeId = this.$route.params.id
  },
}
</script>
