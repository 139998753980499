var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card-text',[_c('v-row',{staticClass:"pb-4 mt-1",attrs:{"justify":"center"}},[(_vm.isChartReady)?_c('g-chart',{staticStyle:{"height":"35vh","width":"79vh"},attrs:{"id":"chart-rebanho","type":"PieChart","data":[
        ['Categoria', 'Quantidade'],
        ['Vacas em lactação', _vm.report.VacasLactacao],
        ['Vacas secas', _vm.report.VacasSecas],
        ['Novilhas', _vm.report.Novilhas],
        ['Machos', _vm.report.Machos],
        ['Recria', _vm.report.Recria],
        ['Aleitamento', _vm.report.Aleitamento],
      ],"options":_vm.chartOptions}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }