<template>
  <v-card class="pb-10">
    <GeneralInformation v-if="structure?.steps[0]" :step="structure.steps[0]" />

    <v-card v-for="step in steps" :key="step.id" class="ma-4 pb-2">
      <v-card-title style="text-align: left">{{ step.title }}</v-card-title>

      <v-row class="ma-3">
        <GraphReport v-if="step" :step="step" />

        <RenderComponents
          v-for="component in step.fields"
          :key="component.name"
          :component="component"
        />
      </v-row>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import GeneralInformation from './GeneralInformation.vue'
import GraphReport from './GraphReport.vue'
import RenderComponents from './RenderComponents.vue'

export default {
  name: 'ReportView',

  components: {
    GeneralInformation,
    RenderComponents,
    GraphReport,
  },

  data() {
    return {}
  },

  computed: {
    ...mapState('report', ['data', 'structure', 'ready']),
    ...mapGetters('report', ['processRecursively', 'removeRecursively']),

    steps() {
      if (!this.structure?.steps) return []

      const structure = JSON.parse(JSON.stringify(this.structure))

      const signatues = []

      this.processRecursively(field => {
        if (field.componentType === 'btnAssinatura') {
          field.show = 'off'
          signatues.push({ ...field })
        }
      })

      let lastStep = structure.steps.filter(step => {
        if (step.title.toLowerCase().includes('assinatura')) {
          return step
        }
      })

      const deleteFieldByName = (structure, fieldName) => {
        return structure.map(item => {
          if (Array.isArray(item.fields)) {
            item.fields = item.fields
              .filter(field => field.name !== fieldName)
              .map(field => deleteFieldByName([field], fieldName)[0])
          }

          for (const key in item) {
            if (Array.isArray(item[key]) && key !== 'fields') {
              item[key] = item[key]
                .filter(field => field.name !== fieldName)
                .map(field => deleteFieldByName([field], fieldName)[0])
            }
          }

          return item
        })
      }

      signatues.forEach(signatue => {
        structure.steps = deleteFieldByName(structure.steps, signatue.name)
      })

      lastStep = lastStep[lastStep.length - 1]

      if (lastStep) {
        lastStep.fields = signatues
      }

      return structure.steps.slice(1, structure.steps.length)
    },
  },

  methods: {},
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
