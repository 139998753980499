<template>
  <div>
    <FilterDashboard :farms="farms" @search="search" />

    <base-loading v-if="loading" />
    <div v-else>
      <v-row align="center" justify="center">
        <v-col cols="8" class="graficoConsultor">
          <h2 class="text-xl-h4 font-weight-medium" style="color: #1b2565">
            CONTROLE DE VISITAS
          </h2>
          <div class="chart-table">
            <table-graph :items="dataVisitsMade" :headers="headersVisitsMade" />
            <base-bar-graph :chart-data="chartDataVisitsMade" :style="styles" />
          </div>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="8" class="graficoConsultor">
          <h2 class="text-xl-h4 font-weight-medium" style="color: #1b2565">
            VOLUME DE LEITE (L/DIA) - ACOMPANHAMENTO MENSAL
          </h2>
          <div class="chart-table">
            <table-graph
              :items="dataAveregeProduction"
              :headers="headersVisitsMade"
            />
            <base-bar-graph
              :chart-data="chartDataAveregeProduction"
              :style="styles"
            />
          </div>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="8" class="graficoConsultor">
          <h2 class="text-xl-h4 font-weight-medium" style="color: #1b2565">
            CPP (CBT) - ACOMPANHAMENTO MENSAL
          </h2>
          <div class="chart-table">
            <table-graph :items="dataCPP" :headers="headersVisitsMade" />
            <base-bar-graph :chart-data="chartDataCPP" :style="styles" />
          </div>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="8" class="graficoConsultor">
          <h2 class="text-xl-h4 font-weight-medium" style="color: #1b2565">
            CCS - ACOMPANHAMENTO MENSAL
          </h2>
          <div class="chart-table">
            <table-graph :items="dataCCS" :headers="headersVisitsMade" />
            <base-bar-graph :chart-data="chartDataCSS" :style="styles" />
          </div>
        </v-col>
      </v-row>

      <v-row cols="10" align="center" justify="center">
        <v-col cols="5" class="graficoConsultor">
          <h2 class="text-xl-h4 font-weight-medium" style="color: #1b2565">
            CCS Média (mil cels/ml)
          </h2>
          <div class="chart-table">
            <base-bar-graph
              :chart-data="chartWeightedAverageCCS"
              :style="styles"
            />
          </div>
        </v-col>

        <v-col cols="5" class="graficoConsultor">
          <h2 class="text-xl-h4 font-weight-medium" style="color: #1b2565">
            CPP Média (mil ufc/ml)
          </h2>
          <div class="chart-table">
            <base-bar-graph
              :chart-data="chartWeightedAverageCPP"
              :style="styles"
            />
          </div>
        </v-col>
      </v-row>

      <v-row align="center" justify="center">
        <v-col cols="10" class="graficoConsultor">
          <v-row cols="12">
            <v-col cols="6">
              <h2 class="text-xl-h4 font-weight-medium" style="color: #1b2565">
                (%) Por intervalo de CCS
              </h2>
              <div class="chart-table">
                <base-stacked-bar
                  :chart-data="chartDataProteinCels"
                  :style="styles"
                />
              </div>
            </v-col>
            <v-col cols="6">
              <h2 class="text-xl-h4 font-weight-medium" style="color: #1b2565">
                (%) Por intervalo de CPP
              </h2>
              <div class="chart-table">
                <base-stacked-bar
                  :chart-data="chartDataProteinCPP"
                  :style="styles"
                />
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row align="center" justify="center">
        <v-col cols="9" class="graficoConsultor">
          <h2 class="text-xl-h4 font-weight-medium" style="color: #1b2565">
            CCS X CBT
          </h2>
          <div class="chart-table">
            <base-scatter-graph
              :chart-data="chartDataDispersion"
              :style="styles"
            />
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import _ from 'underscore'

import TableGraph from '../../../../components/base/TableGraph.vue'
import {
  activeProducersList,
  assosiationLabel,
  monthShortLabel,
  renderDatasetGraph,
  renderDatasetTable,
  renderDispersion,
} from '../../../../utils/dashboard/dashboard'

import educampoDashboardService from '../../../../services/api/dashboard/educampo'
import {
  avgProteinAndFat,
  proteinForIntervalCCS,
  proteinForIntervalCPP,
  weightedAverageCCS,
} from '../../../../utils/dashboard/dashboardCCS'

import FilterDashboard from '../../filter/index.vue'

export default {
  name: 'Dashboard',
  components: {
    TableGraph,
    FilterDashboard,
  },

  emits: ['dashboard'],

  data() {
    return {
      activeProducersList,
      loading: true,
      farms: [],

      form: {
        produtor: '',
        consultor: '',
        agroindustria: '',
        fazenda: '',
        activeProducers: activeProducersList[0],
        dataInicio: this.moment()
          .subtract(11, 'months')
          .toISOString(true)
          .substr(0, 10),
        dataFim: this.moment().toISOString(true).substr(0, 10),
      },

      responsiveOptions: [
        [
          'screen and (max-width: 640px)',
          {
            seriesBarDistance: 5,
            axisX: {
              labelInterpolationFnc: function (value) {
                return value[0]
              },
            },
          },
        ],
      ],

      styles: {
        backgroundColor: '#f1f1f1',
      },

      headersVisitsMade: [],
      dataVisitsMade: [],
      dataAveregeProduction: [],
      dataCPP: [],
      dataCCS: [],
      dataClinicalMastitis: [],
      dataChronicMastitis: [],
      dataHealing: [],
      dataInfections: [],

      chartDataVisitsMade: {
        labels: monthShortLabel,
        datasets: [{ data: [0], label: '' }],
      },
      chartDataCPP: {
        labels: monthShortLabel,
        datasets: [{ data: [0], label: '' }],
      },
      chartDataCSS: {
        labels: monthShortLabel,
        datasets: [{ data: [0], label: '' }],
      },
      chartDataAveregeProduction: {
        labels: monthShortLabel,
        datasets: [{ data: [0], label: '' }],
      },

      chartDataProteinCels: {
        labels: monthShortLabel,
        datasets: [{ data: [0], label: '' }],
      },

      chartDataProteinCPP: {
        labels: monthShortLabel,
        datasets: [{ data: [0], label: '' }],
      },

      chartWeightedAverageCCS: {
        labels: monthShortLabel,
        datasets: [{ data: [0], label: '' }],
      },

      chartWeightedAverageCPP: {
        labels: monthShortLabel,
        datasets: [{ data: [0], label: '' }],
      },

      chartDataDispersion: {
        datasets: [
          {
            label: 'CCS X CBT',
            fill: false,
            borderColor: 'rgb(40, 65, 106)',
            backgroundColor: 'rgb(40, 65, 106)',
            data: [],
          },
        ],
      },

      optionsCPP1: {
        seriesBarDistance: 10,
      },
    }
  },

  created() {
    this.dashboardReport()
  },

  methods: {
    renderDatasetGraph,
    renderDatasetTable,
    proteinForIntervalCCS,
    proteinForIntervalCPP,
    avgProteinAndFat,
    weightedAverageCCS,
    renderDispersion,

    dashboardReport() {
      return new Promise((resolve, reject) => {
        this.loading = true

        educampoDashboardService
          .dashboard(
            this.form.dataInicio,
            this.form.dataFim,
            this.form.produtor,
            this.form.fazenda,
            this.form.agroindustria,
            this.form.consultor,
            9,
            this.form.activeProducers,
          )
          .then(async response => {
            this.dashboardReportData = response.data
            this.$emit('dashboard', response.data)

            await this.filter(this.dashboardReportData.mpufcCSS)

            const resultVisitsMade = _.groupBy(
              this.dashboardReportData.visitsMade,
              item => item.year,
            )

            this.headersVisitsMade = [
              { text: '', value: 'year' },
              ...Object.keys(resultVisitsMade).map(item => {
                return { text: item, value: item }
              }),
              ...assosiationLabel,
            ]

            const resultCPP = _.groupBy(
              this.dashboardReportData.averegeCPP,
              item => item.year,
            )

            const resultCSS = _.groupBy(
              this.dashboardReportData.averegeCSS,
              item => item.year,
            )

            const averegeProduction = _.groupBy(
              this.dashboardReportData.averegeProduction,
              item => item.year,
            )

            this.chartDataVisitsMade.datasets = this.renderDatasetGraph(
              resultVisitsMade,
            )
            const result = this.renderDatasetTable(
              this.chartDataVisitsMade.datasets,
            )

            const resultData = {}

            Object.entries(resultVisitsMade).forEach(([key, value]) => {
              let sum = 0
              value.forEach(item => {
                sum += item.avg
              })
              resultData[key] = sum
            })

            result.push({
              year: 'Realizado no ano',
              class: 'blue-row',
              ...resultData,
            })

            this.dataVisitsMade = result

            this.chartDataCPP.datasets = this.renderDatasetGraph(resultCPP)
            this.dataCPP = this.renderDatasetTable(this.chartDataCPP.datasets)

            this.dataAveregeProduction = this.renderDatasetTable(
              this.chartDataAveregeProduction.datasets,
            )

            this.chartDataCSS.datasets = this.renderDatasetGraph(resultCSS)
            this.dataCCS = this.renderDatasetTable(this.chartDataCSS.datasets)

            this.chartDataAveregeProduction.datasets = this.renderDatasetGraph(
              averegeProduction,
            )
            this.dataAveregeProduction = this.renderDatasetTable(
              this.chartDataAveregeProduction.datasets,
            )

            this.chartDataDispersion = {
              datasets: [
                {
                  label: 'Análise CCS x CPP',
                  fill: false,
                  borderColor: 'rgb(40, 65, 106)',
                  backgroundColor: 'rgb(40, 65, 106)',
                  data: this.renderDispersion(response.data.CSSAndCPP),
                },
              ],
            }

            this.chartDataProteinCels = this.proteinForIntervalCCS(
              this.dashboardReportData.mpCelsCSS,
            )

            this.chartDataProteinCPP = this.proteinForIntervalCPP(
              this.dashboardReportData.mpufcCSS,
            )

            this.chartWeightedAverageCCS.datasets = this.weightedAverageCCS(
              this.dashboardReportData.mpCelsCSS,
              'ccsMedia',
            )

            this.chartWeightedAverageCPP.datasets = this.weightedAverageCCS(
              this.dashboardReportData.mpufcCSS,
              'cppMedia',
            )

            this.loading = false
            return resolve()
          })
          .catch(error => {
            return reject(error)
          })
      })
    },

    async filter(data) {
      const farms = [
        ...new Set(
          data?.map(item => {
            return item.farm
          }),
        ),
      ]

      this.farms = farms
    },

    async search(data) {
      this.form = data

      this.dashboardReport()
    },

    getDashboard() {
      this.loading = true
      this.dashboardReport().catch(() => {
        this.Swal.fire({
          title: 'Erro',
          text: 'Erro ao carregar dados',
          type: 'error',
        }).finally(() => {
          this.loading = false
        })
      })
    },
  },
}
</script>
